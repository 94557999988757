import React, { Component } from 'react'
import Cmp from './Menu'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            allPrismicMenu(filter: {data: {titill: {text: {eq: "Menu"}}}}) {
              edges {
                node {
                  data {
                    nav {
                      __typename
                      ... on PrismicMenuNavNavItem {
                        id
                        primary {
                          label {
                            text
                          }
                          top_level_link {
                            url
                            document {
                              ... on PrismicPage {
                                uid
                                data {
                                  page_parent {
                                    uid
                                  }
                                }
                              }
                            }
                          }
                        }
                        items {
                          sub_nav_link_label {
                            text
                          }
                          sub_nav_link {
                            url
                            document {
                              ... on PrismicPage {
                                id
                                uid
                                data {
                                  page_parent {
                                    uid
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <Cmp data={data} {...this.props} />}
      />
    )
  }
}

export default Connected
