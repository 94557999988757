import React, { Component, Fragment } from 'react'
import googleMapStyles from './gmap-styles.js'
import className from 'classnames'
import { compose, withProps } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import styles from './GoogleMap.module.scss'
import blueMarker from '../../images/markers/marker-blue.png'
import greenMarker from '../../images/markers/marker-green.png'
import orangeMarker from '../../images/markers/marker-orange.png'
import purpleMarker from '../../images/markers/marker-purple.png'
import { get, has } from 'lodash'
import chefHat from '../../images/icons/chef-hat.svg'
import { hyphenate } from '../../utils/hypher.js'
import { Link } from '../'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHorse,
  faBooks,
  faPlug,
  faShip,
  faPlane,
  faFilm,
  faMicroscope,
  faDatabase,
  faFish,
  faLightbulb
} from '@fortawesome/pro-solid-svg-icons'

const markerTypes = {
  'Blue': blueMarker,
  'Green': greenMarker,
  'Orange': orangeMarker,
  'Purple': purpleMarker
}

const iconTypes = {
  'Hestar': faHorse,
  'Bækur': faBooks,
  'Orka': faPlug,
  'Sjávarútvegur': faShip,
  'Ferðalög': faPlane,
  'Film': faFilm,
  'Lífvísindi': faMicroscope,
  'Gagnaver': faDatabase,
  'Fiskur': faFish,
  'Nýsköpun': faLightbulb
}

const MyMapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBvCm7ZSZx7mxmSxBOQBrD7sXoJlsI2DMc&v=3',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `800px` }} />,
    mapElement: <div style={{ height: `800px` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  return (
    <GoogleMap
      disableDefaultUI
      defaultOptions={{
        styles: googleMapStyles
      }}
      defaultZoom={3}
      defaultCenter={{ lat: 40, lng: 0 }}>

      {props.markers.reduce((sum, cur, idx) => {
        if (!props.toggledFilter || props.toggledFilter.id === get(cur, 'primary.marker_type.document[0].id')) {
          const markerColor = get(cur, 'primary.marker_type.document[0].data.color', '')
          const link = get(cur, 'primary.marker_link.url', '')
          const linkName = get(cur, 'primary.marker_link_name.text', '')
          const popupStyles = className(styles.popup, {
            [styles.popupWLink]: link && linkName
          })

          sum.push(
            <Marker
              key={idx}
              position={{
                lat: parseFloat(get(cur, 'primary.marker_location.latitude', 0)),
                lng: parseFloat(get(cur, 'primary.marker_location.longitude', 0))
              }}
              icon={markerTypes[markerColor]}
              onClick={() => props.onToggleOpen(idx)}>
              {(props.toggled === idx) &&
                <InfoWindow onCloseClick={props.onToggleOpen}>
                  <div className={popupStyles}>
                    {cur.infoContent}
                    {link && linkName &&
                      <Link to={link}>{linkName}</Link>
                    }
                  </div>
                </InfoWindow>}
            </Marker>
          )
        }

        return sum
      }, [])}
    </GoogleMap>
  )
})

class MyGoogleMap extends Component {
  constructor () {
    super()

    this.state = {
      toggledFilter: null
    }

    this.setFilters = this.setFilters.bind(this)
    this.onToggleFilter = this.onToggleFilter.bind(this)
  }

  componentWillMount () {
    this.setFilters()
  }

  onToggleOpen (index) {
    this.setState({
      toggled: index
    })
  }

  onToggleFilter (filter) {
    this.setState({
      toggledFilter: this.state.toggledFilter === filter
        ? null
        : filter
    })
  }

  setFilters () {
    const { primary } = this.props
    const filters = this.getFilters(get(primary, 'kort.document[0].data.body', []))

    filters.map(x => {
      this.includeFilter(x)
    })
  }

  getFilters (markers) {
    const filters = markers.map((mark) => {
      return get(mark, 'primary.marker_type.document[0]')
    })

    return filters.reduce((unique, item, index) => {
      if (unique.find(x => get(x, 'id') === get(item, 'id'))) {
        return unique
      }

      return [...unique, item]
    }, [])
  }

  includeFilter (filter) {
    const { primary } = this.props
    const hasCategoryDisplay = has(primary, 'category_display.id')
    const categoryDisplayId = get(primary, 'category_display.id', '')
    const filterId = get(filter, 'prismicId', '')

    if (hasCategoryDisplay && categoryDisplayId === filterId) {
      this.setState({
        toggledFilter: filter
      })
    }
  }

  getInfoContent (markers) {
    return markers.map((mark, i) => {
      const iconName = get(mark, 'primary.marker_icon', '')
      const isFontAwesomeIcon = iconName && iconName !== 'Matur'

      mark.infoContent = <Fragment>
        <div className={styles.popupHeading}>
          <h3>{get(mark, 'primary.marker_title.text', '')}</h3>
          <div className={styles.popupIcon}>
            {isFontAwesomeIcon &&
              <FontAwesomeIcon icon={iconTypes[mark.primary.marker_icon]} />
            }
            {!isFontAwesomeIcon &&
              <img src={chefHat} />
            }
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: get(mark, 'primary.marker_text.html', '') }} />
      </Fragment>
      return mark
    })
  }

  render () {
    const {
      toggledFilter
    } = this.state

    const { primary } = this.props
    const mapArray = get(primary, 'kort.document', [])
    const mapTitle = get(primary, 'titill.text', '')
    const mapText = get(primary, 'texti.html', '')

    return (
      <div className={styles.container}>
        {mapArray.length > 0 && mapArray.map((map, i) => {
          const markers = this.getInfoContent(get(map, 'data.body', []))
          const filters = this.getFilters(markers)

          return (
            <div key={i}>
              <div className='container'>
                {mapTitle &&
                  <h2 className='hdln--1'>{hyphenate(mapTitle)}</h2>
                }
                {mapText &&
                  <div className='mt-2 mb-2' dangerouslySetInnerHTML={{ __html: mapText }} />
                }
              </div>
              <div className={className(styles.filterContainer)}>
                {filters.reduce((items, cur, i) => {
                  const color = get(cur, 'data.color', '')
                  const text = get(cur, 'data.taxonomy_term.text', '')
                  const disableFilter = toggledFilter && cur !== toggledFilter

                  if (text) {
                    items.push(
                      <div
                        key={i}
                        onClick={() => this.onToggleFilter(cur)}
                        className={className(styles.filterOption, {
                          [styles.filterOptionDisabled]: disableFilter
                        })}>
                        {color && markerTypes.hasOwnProperty(color) && <img src={markerTypes[color]} />}
                        <span>{text}</span>
                      </div>
                    )
                  }

                  return items
                }, [])}
              </div>

              <MyMapComponent
                filters={filters}
                toggledFilter={this.state.toggledFilter}
                onToggleFilter={this.onToggleFilter.bind(this)}
                onToggleOpen={this.onToggleOpen.bind(this)}
                toggled={this.state.toggled}
                markers={markers} />
            </div>
          )
        })}
      </div>
    )
  }
}

export default MyGoogleMap
