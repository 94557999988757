import Cmp from './Page.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query pageConnected($id: String!) {
    prismicPage(prismicId: {eq: $id}) {
      prismicId
      data {
        title {
          html
          text
        }
        top_title {
          text
        }
        bottom_title {
          text
        }
        button_text {
          text
        }
        link {
          link_type
          url
          document {
            ... on PrismicPage {
              uid
              data {
                page_parent {
                  uid
                }
              }
            }
          }
        }
        darken_background_image
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1400, quality: 92) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        body {
          __typename
          ... on PrismicPageBodyTextasvIMeMynd {
            slice_type
            primary {
              texti {
                html
              }
              myndatexti {
                text
              }
              link_name {
                text
              }
              link_url {
                url
                link_type
                document {
                  ... on PrismicPage {
                    id
                    uid
                    data {
                      page_parent {
                        uid
                      }
                    }
                  }
                }
                link_type
              }
              mynd {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyMyndband {
            id
            slice_type
            primary {
              video {
                embed_url
              }
            }
          }
          ... on PrismicPageBodyLykiltolur {
            id
            slice_type
            primary {
              big_title {
                text
              }
              buttontext {
                text
              }
              short_title {
                text
              }
              text {
                html
              }
              button_link {
                document {
                  ... on PrismicPage {
                    id
                    data {
                      page_parent {
                        uid
                      }
                    }
                  }
                }
              }
            }
            items {
              column_title {
                text
              }
              number {
                text
              }
            }
          }
          ... on PrismicPageBodyTitill {
            id
            slice_type
            primary {
              large_title {
                text
              }
              short_title {
                text
              }
            }
          }
          ... on PrismicPageBodyTafla {
            slice_type
            items {
              row_tegund
              col_1 {
                text
              }
              col_2 {
                text
              }
              col_3 {
                text
              }
            }
          }
          ... on PrismicPageBodySvipmyndir {
            id
            slice_type
            primary {
              gallery_title {
                text
                html
              }
            }
            items {
              image_title {
                text
              }
              image_description {
                text
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicPageBodyKort {
            slice_type
            primary {
              category_display {
                id
              }
              titill {
                text
              }
              texti {
                html
              }
              kort {
                document {
                  ... on PrismicMap {
                    id
                    data {
                      title {
                        text
                      }
                      body {
                        ... on PrismicMapBodyMarker {
                          id
                          primary {
                            marker_icon
                            marker_title {
                              text
                            }
                            marker_text {
                              html
                            }
                            marker_link_name {
                              text
                            }
                            marker_link {
                              url
                            }
                            marker_location {
                              latitude
                              longitude
                            }
                            marker_type {
                              document {
                                ... on PrismicMapTaxonomy {
                                  id
                                  prismicId
                                  data {
                                    taxonomy_term {
                                      text
                                    }
                                    color
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyStorMynd {
            slice_type
            primary {
              description {
                html
                text
              }
              image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 92) {
                      base64
                      tracedSVG
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTilvitnun {
            slice_type
            primary {
              tilvitnun {
                html
                text
              }
            }
          }
          ... on PrismicPageBodyText {
            id
            slice_type
            primary {
              link_name {
                text
              }
              link_url {
                url
                document {
                  ... on PrismicPage {
                    id
                    uid
                    data {
                      page_parent {
                        uid
                      }
                    }
                  }
                }
                link_type
              }
              text {
                html
              }
            }
          }
          ... on PrismicPageBodyHero {
            slice_type
            primary {
              hero_title {
                html
                text
              }
              hero_short_title {
                html
                text
              }
              hero_text {
                html
                text
              }
              hero_button_text {
                html
                text
              }
              hero_link {
                link_type
                url
                document {
                  __typename
                  ... on PrismicPage {
                    uid
                    data {
                      page_parent {
                        uid
                      }
                    }
                  }
                  ... on PrismicTopLevelPage {
                    uid
                    data {
                      title {
                        html
                        text
                      }
                    }
                  }
                }
              }
              hero_image {
                alt
                localFile {
                  childImageSharp {
                    fluid(cropFocus: ATTENTION, quality: 92, maxWidth: 800) {
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Cmp
